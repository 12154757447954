.App {
text-align: center;
}

.App-logo {
animation: App-logo-spin infinite 10s linear;
position: absolute;
height: 20vmin;
pointer-events: none;
opacity: 0.2;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  /* font-size: 48vh; */
  font-size: 26vw;
  /* font-size: 300px; */
  line-height: 1em;
  /* text-transform: uppercase */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}